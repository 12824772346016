import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import Cookies from 'universal-cookie';

import * as http from '_http';
import * as Auth from 'util/auth';
import ErrorBoundary from 'helpers/ErrorBoundary';
import AppBar from 'components/AppBar/AppBar';
import AppLoader from 'components/AppLoader';
import openNotification from 'components/openNotification';
import LoginPage from './pages/LoginPage';

function App({ location, changeLanguage }) {
  const [userDetails, setUserDetails] = useState(null);
  const [specialOrderCustomers, setSpecialOrderCustomers] = useState([]);
  const [selectedSpecialOrderCustomer, setSelectedSpecialOrderCustomer] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState({
    customerId: null,
    opco: null,
    customerDetails: { customerInfo: { name: null } }
  });
  const [isCustomerSelectorVisible, setIsCustomerSelectorVisible] = useState(false);
  const [isCustomerFilterVisible, setIsCustomerFilterVisible] = useState(false);
  const { update: updateIntercom, shutdown: shutdownIntercom } = useIntercom();

  const { i18n, t } = useTranslation();
  const currentLocation = useLocation();

  // set access token as a cookie in local environment
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'local') {
      const params = new URLSearchParams(location.search);
      const userClaims = params.get('user_claims');

      if (userClaims) {
        const cookies = new Cookies();
        cookies.set('user-claims', userClaims, { path: '/' });
      }
    }
  }, [location.search]);

  // get and set user details
  useEffect(() => {
    const getUserDetails = async () => {
      if (!userDetails) {
        setIsUserLoading(true);
        try {
          const { data: userData } = await http.getRequest('/user');
          if (userData?.userDetails?.customerId) {
            setUserDetails(userData);
            const { languagePreference } = userData?.userDetails;
            changeLanguage(languagePreference);
          }
        } catch (e) {
          console.log(e);
          openNotification({
            description: t('landing.errorNotification.description.userFetch'),
            className: 'error',
            message: t('landing.errorNotification.message.userFetch')
          });
        }
      }
      setIsUserLoading(false);
    };
    getUserDetails();
  }, [userDetails, setUserDetails]);

  // set default customer as selected customer
  useEffect(() => {
    if (userDetails?.userDetails?.defaultCustomer) {
      const { defaultCustomer } = userDetails.userDetails;
      const defaultCustomerDetails = userDetails.customerList.find(
        customer => customer.customerId === defaultCustomer.customerId && customer.opco === defaultCustomer.opco
      );
      setSelectedCustomer(defaultCustomerDetails);
    }
  }, [userDetails]);

  // initiate intercom instance
  useEffect(() => {
    const { email, languagePreference } = userDetails?.userDetails || {};

    if (email) {
      const intercomOptions = { email: email[0], languageOverride: languagePreference };
      updateIntercom(intercomOptions);
      Auth.setLogoutCallback(shutdownIntercom);
    }
  }, [userDetails, updateIntercom, shutdownIntercom]);

  // hide/show intercom based on the route
  useEffect(() => {
    if (currentLocation.pathname === '/suite/specialorders') {
      window.Intercom('shutdown');
    } else {
      window.Intercom('boot', { app_id: process.env.REACT_APP_INTERCOM_APP_ID });
    }
  }, [currentLocation]);

  // eslint-disable-next-line no-undef
  const remoteImport = async url => (await SystemJS.import(url)).default;

  const { language } = i18n;

  return (
    <div className="app-container main-container">
      <AppBar
        userDetails={userDetails}
        selectedCustomer={selectedCustomer}
        selectCustomer={setSelectedCustomer}
        isCustomerSelectorVisible={isCustomerSelectorVisible}
        isCustomerFilterVisible={isCustomerFilterVisible}
        changeLanguage={changeLanguage}
        specialOrderCustomers={specialOrderCustomers}
        setSelectedSpecialOrderCustomer={setSelectedSpecialOrderCustomer}
        selectedSpecialOrderCustomer={selectedSpecialOrderCustomer}
      />
      <ErrorBoundary>
        {!isUserLoading ? (
          <Router>
            <Route
              path="/suite/dashboard"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/dashboard')}
                  mountParcel={mountRootParcel}
                  wrapWith="section"
                  language={language}
                  className="app-container"
                  isCustomerSelectorVisible={isCustomerSelectorVisible}
                  setIsCustomerSelectorVisible={setIsCustomerSelectorVisible}
                  isCustomerFilterVisible={isCustomerFilterVisible}
                  setIsCustomerFilterVisible={setIsCustomerFilterVisible}
                  Auth={Auth}
                />
              )}
            />
            <Route
              path="/suite/delivery"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/delivery')}
                  mountParcel={mountRootParcel}
                  wrapWith="section"
                  language={language}
                  className="app-container"
                  // TODO: pass essential customer details only
                  userDetails={userDetails}
                  isCustomerSelectorVisible={isCustomerSelectorVisible}
                  setIsCustomerSelectorVisible={setIsCustomerSelectorVisible}
                  selectCustomer={setSelectedCustomer}
                  selectedCustomer={selectedCustomer}
                  Auth={Auth}
                />
              )}
            />
            <Route
              path="/suite/specialorders"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/specialorders')}
                  mountParcel={mountRootParcel}
                  wrapWith="section"
                  language={language}
                  className="app-container"
                  userDetails={userDetails}
                  isCustomerFilterVisible={isCustomerFilterVisible}
                  setIsCustomerFilterVisible={setIsCustomerFilterVisible}
                  selectedSpecialOrderCustomer={selectedSpecialOrderCustomer}
                  setSelectedSpecialOrderCustomer={setSelectedSpecialOrderCustomer}
                  setSpecialOrderCustomers={setSpecialOrderCustomers}
                  Auth={Auth}
                />
              )}
            />
            <Route path="/login" render={() => <LoginPage changeLanguage={changeLanguage} />} />
          </Router>
        ) : (
          <AppLoader />
        )}
      </ErrorBoundary>
    </div>
  );
}

export default App;
