const AppCollection = [
  {
    divClassName: 'tile costing hover-brighten',
    spanClassName: 'fi flaticon-budget',
    appName: 'landing.costing'
  },
  {
    divClassName: 'tile promotions hover-brighten',
    spanClassName: 'fi flaticon-megaphone',
    appName: 'landing.promotions'
  },
  {
    divClassName: 'tile marketing hover-brighten',
    spanClassName: 'fi flaticon-billboard',
    appName: 'landing.marketing'
  },
  {
    divClassName: 'tile payments hover-brighten',
    spanClassName: 'fi flaticon-money',
    appName: 'landing.payments'
  },
  {
    divClassName: 'tile rewards hover-brighten',
    spanClassName: 'fi flaticon-medal',
    appName: 'landing.rewards'
  },
  {
    divClassName: 'tile agreements hover-brighten',
    spanClassName: 'fi flaticon-agreement',
    appName: 'landing.agreements'
  },
  {
    divClassName: 'tile eiprograms hover-brighten',
    spanClassName: 'fi flaticon-list',
    appName: 'landing.eiPrograms'
  },
  {
    divClassName: 'tile claims hover-brighten',
    spanClassName: 'fi flaticon-refund',
    appName: 'landing.claims'
  },
  {
    divClassName: 'tile itemmanager hover-brighten',
    spanClassName: 'fi flaticon-plan',
    appName: 'landing.itemManager'
  },
  {
    divClassName: 'tile documentbackuprepo hover-brighten',
    spanClassName: 'fi flaticon-upload',
    appName: 'landing.documentBackupRepository'
  },
  {
    divClassName: 'tile opportunities hover-brighten',
    spanClassName: 'fi flaticon-idea',
    appName: 'landing.opportunities'
  },
  {
    divClassName: 'tile suppliermanagement hover-brighten',
    spanClassName: 'fi flaticon-team',
    appName: 'landing.supplierManagement'
  }
];

export default AppCollection;
