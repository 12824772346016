import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import App from 'App';
import LoginPage from 'pages/LoginPage';
import SignUpPage from 'pages/SignUpPage';
import ProtectedRoute from 'components/ProtectedRoute';
import { AppRouteTypes, Languages } from 'enums';
// Keep primary scss file at the bottom of the import list to avoid library overrides of styles
import 'styles/App.scss';

function MainApp() {
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  const { i18n } = useTranslation();
  const cookies = new Cookies();
  const changeLanguage = lng => {
    if (Languages[lng.toUpperCase()]) {
      i18n.changeLanguage(lng);
      cookies.set('lng', lng, { path: '/' });
    } else i18n.changeLanguage(Languages.EN);
  };

  return (
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID} autoBoot>
      <Router>
        <Switch>
          <ProtectedRoute
            path="/login"
            type={AppRouteTypes.LOGIN_ROUTE}
            component={LoginPage}
            changeLanguage={changeLanguage}
          />
          <ProtectedRoute
            path="/suite/*"
            type={AppRouteTypes.PRIVATE_ROUTE}
            component={App}
            changeLanguage={changeLanguage}
          />
          <Route path="/signup" component={SignUpPage} />
          <Route path="*" render={() => <LoginPage changeLanguage={changeLanguage} />} />
        </Switch>
      </Router>
    </IntercomProvider>
  );
}
export default MainApp;
