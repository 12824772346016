import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Row, Col, Input, Form, Select, Button, InputNumber } from 'antd';
import PhoneInput from 'react-phone-input-2';
import { getRequest, postRequest } from '_http';
import { useTranslation } from 'react-i18next';
import ThankYouPage from './ThankYouPage';
import {
  EMPTY_STRING,
  MAX_LENGTH_NAME,
  NAME_VALIDATION_PATTERN,
  MAX_LENGTH_CUSTOMER_NUMBER,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_COUNTRY_CODE,
  NUMBER_VALIDATION_PATTERN,
  PHONE_NUMBER_LENGTH
} from '../util/constants';
import '../styles/apps/signup/_signup.scss';
import 'react-phone-input-2/lib/style.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function SignUpPage(props) {
  const [opcoLocations, setOpcoLocations] = useState([]);
  const [userCreationSuccess, setUserCreationSuccess] = useState(false);
  const [name, setName] = useState(EMPTY_STRING);
  const [customerName, setCustomerName] = useState(EMPTY_STRING);
  const [customerNumber, setCustomerNumber] = useState(EMPTY_STRING);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [phone, setPhone] = useState(EMPTY_STRING);
  const [salesRepresentativeName, setSalesRepresentativeName] = useState(null);
  const [opcoId, setOpcoId] = useState(EMPTY_STRING);
  const handleLocation = province => {
    setOpcoId(province?.trim());
  };
  const [isSubmitted, setIsSubmitted] = useState(false);

  const getOpcoDetails = async () => {
    const {
      data: { opcoLocations: locations }
    } = await getRequest(`/opco-locations`);
    return locations;
  };
  const { t } = useTranslation();
  const postSignUpDetails = async customerData => {
    try {
      await postRequest(`/signup`, customerData);
      setUserCreationSuccess(true);

      setName(EMPTY_STRING);
      setEmail(EMPTY_STRING);
      setPhone(EMPTY_STRING);
      setOpcoId(EMPTY_STRING);
      setCustomerName(EMPTY_STRING);
      setCustomerNumber(EMPTY_STRING);
      setSalesRepresentativeName(EMPTY_STRING);
    } catch (error) {
      console.info(error);
    }
    setIsSubmitted(false);
  };

  useEffect(async () => {
    const locations = await getOpcoDetails();
    setOpcoLocations(locations);
  }, []);

  // 1. Destructure props
  const { form } = props;
  const { getFieldDecorator } = form;

  const handleCustomerSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll(err => {
      if (!err) {
        if (
          name !== EMPTY_STRING &&
          customerName !== EMPTY_STRING &&
          customerNumber !== EMPTY_STRING &&
          email !== EMPTY_STRING &&
          phone !== EMPTY_STRING &&
          opcoId !== EMPTY_STRING
        ) {
          const customerData = {
            name: name?.trim(),
            email: email?.trim(),
            phone,
            opcoId: opcoId?.trim(),
            customerName: customerName?.trim(),
            customerNumber,
            salesRepresentativeName: salesRepresentativeName?.trim()
          };
          postSignUpDetails(customerData);
          setIsSubmitted(true);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Row className="wrapper login-wrapper model-body signup">
        <Col span={14} className="container">
          {!isSubmitted && !userCreationSuccess ? (
            <React.Fragment>
              <div className="title">{t('landing.signUp.SubscribeForSyscoPortal')}</div>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  handleCustomerSubmit(e);
                }}
              >
                <Col>
                  <Form.Item className="catelog-lable" htmlFor="name" label={t('landing.signUp.yourName')}>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: t('landing.signUp.invalidName')
                        }
                      ]
                    })(
                      <Input
                        id="name"
                        className="input-first"
                        placeholder={t('landing.signUp.yourName')}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        maxLength={MAX_LENGTH_NAME}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item className="catelog-lable" htmlFor="email" label={t('landing.signUp.email')}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: t('landing.signUp.invalidEamail')
                        },
                        {
                          required: true,
                          message: t('landing.signUp.invalidEamail')
                        }
                      ]
                    })(
                      <Input
                        id="email"
                        className="input-first"
                        placeholder={t('landing.signUp.email')}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item label={t('landing.signUp.phone')} className="catelog-lable">
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: true,
                          message: t('landing.signUp.invalidPhone')
                        },
                        { min: PHONE_NUMBER_LENGTH, message: t('landing.signUp.invalidPhone') }
                      ]
                    })(
                      <PhoneInput
                        id="phone"
                        className="input-first"
                        country={PHONE_NUMBER_COUNTRY_CODE}
                        onlyCountries={[PHONE_NUMBER_COUNTRY_CODE]}
                        value={phone}
                        onChange={(value, data) =>
                          setPhone(
                            value?.replace(NUMBER_VALIDATION_PATTERN, EMPTY_STRING).slice(data?.dialCode?.length)
                          )
                        }
                        masks={{ ca: PHONE_NUMBER_MASK }}
                        countryCodeEditable={false}
                        disableDropdown
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={t('landing.signUp.location')}>
                    {getFieldDecorator('location', {
                      rules: [{ required: true, message: t('landing.signUp.selectLocation') }]
                    })(
                      <Select
                        dropdownMatchSelectWidth
                        className="form-select"
                        placeholder={t('landing.signUp.selectLocation')}
                        onChange={value => handleLocation(value)}
                        required
                      >
                        {opcoLocations?.length &&
                          opcoLocations.map(opco => {
                            return (
                              <Select.Option key={opco.opcoId} value={opco.opcoId}>
                                {`${opco.opcoId} - ${opco.opcoName}`}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item className="catelog-lable" htmlFor="customer-name" label={t('landing.signUp.customerName')}>
                    {getFieldDecorator('customername', {
                      rules: [
                        {
                          required: true,
                          message: t('landing.signUp.invalidCustomerName')
                        }
                      ]
                    })(
                      <Input
                        id="name"
                        className="input-first"
                        placeholder={t('landing.signUp.customerName')}
                        value={customerName}
                        onChange={e => setCustomerName(e.target.value)}
                        maxLength={MAX_LENGTH_NAME}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t('landing.signUp.customerNumber')}
                    className="catelog-lable"
                    htmlFor="customerNumber"
                  >
                    {getFieldDecorator('customerNuber', {
                      rules: [
                        {
                          required: true,
                          message: t('landing.signUp.invalidCustomerNumber')
                        }
                      ]
                    })(
                      <InputNumber
                        maxLength={MAX_LENGTH_CUSTOMER_NUMBER}
                        id="customerNumber"
                        className="input-first"
                        placeholder={t('landing.signUp.customerNumber')}
                        value={customerNumber}
                        onChange={value => setCustomerNumber(value)}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="catelog-lable"
                    htmlFor="customer-name"
                    label={t('landing.signUp.salesRepNameOpt')}
                  >
                    <Input
                      className="input-first"
                      placeholder={t('landing.signUp.salesRepNameOpt')}
                      value={salesRepresentativeName}
                      onChange={e => setSalesRepresentativeName(e.target.value)}
                      maxLength={MAX_LENGTH_NAME}
                      pattern={NAME_VALIDATION_PATTERN}
                      title={t('landing.signUp.invalidName')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} />
                <Col span={12} className="submit-col">
                  <span className="linkSeparator"></span>
                  <Link to="/login">
                    <Button className="back-btn" type="primary">
                      {t('landing.signUp.back')}
                    </Button>
                  </Link>
                  <span className="linkSeparator"> </span>
                  <Button className="submit-btn" htmlType="submit">
                    {t('landing.signUp.submit')}
                  </Button>
                </Col>
              </Form>
            </React.Fragment>
          ) : (
            [
              userCreationSuccess ? (
                <ThankYouPage key="thankYouMessage" />
              ) : (
                <Loader key="loader" type="TailSpin" color="#00BFFF" height={50} width={50} timeout={7000} />
              )
            ]
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Form.create()(SignUpPage);
