import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import * as Auth from 'util/auth';
import { Languages, LoginTypes } from 'enums/index';
import * as http from '_http';
import AccountSecurityModal from 'components/AccountSecurityModal';
import { LockFilled } from '@ant-design/icons';
import openNotification from 'components/openNotification';
import { LANGUAGE_PREFERENCE } from 'util/constants';
import useModalToggle from 'hooks/useModalToggle';

function UserWidget({ t, changeLanguage, username, loginType, initMfaBanner, selectCustomer }) {
  const [settingsVisible, setSettingsVisible] = useModalToggle();
  const [securityModalVisible, setSecurityModalVisible] = useModalToggle();

  const isOktaLogin = loginType === LoginTypes.OKTA_LOGIN;

  useEffect(() => {
    const updateMfaBannerPreference = async () => {
      try {
        await http.postRequest('/user/user-preference', { initMfaBanner: true });
      } catch (e) {
        console.log('MFA Banner preference attribute update failed', e);
      }
    };

    /*
      For the first time, MFA pop-up banner will be viewed to the users
      and this MFA-Banner attribute will be preserved to avoid recurring pop-ups
    */
    if (isOktaLogin && !initMfaBanner) {
      setSecurityModalVisible();
      updateMfaBannerPreference();
    }
  }, [initMfaBanner]);

  const logout = () => {
    selectCustomer({
      customerId: null,
      customerDetails: { customerInfo: { name: null } }
    });
    Auth.logout();
  };

  const changeLanguagePreference = async language => {
    changeLanguage(language);
    try {
      await http.patchRequest(`/user/language/${LANGUAGE_PREFERENCE[language]}`);
    } catch (e) {
      openNotification({
        description: t('landing.errorNotification.description.languageError'),
        className: 'error',
        message: t('landing.errorNotification.message.languageError')
      });
    }
  };

  return (
    <>
      <div id="user-widget" className={settingsVisible ? 'user-widget show' : 'user-widget'}>
        <div className="user">
          <div className="name">{username}</div>
          <div className="welcome">{username ? `${t('landing.userWidget.signedIn')}` : ''}</div>
        </div>
        <div
          id="user-pic"
          className="user-pic hover-brighten"
          role="button"
          tabIndex="0"
          onKeyPress={setSettingsVisible}
          onClick={setSettingsVisible}
        >
          <div className="pic"></div>
          <span className="menuicon fi flaticon-caret-down " />
        </div>
        {settingsVisible && (
          <div
            id="account-menu"
            className="account-menu show"
            role="button"
            tabIndex="-1"
            onKeyPress={() => {}}
            onClick={setSettingsVisible}
          >
            <ul>
              <li className="hide" id="ddl-change-profile">
                <span className="icon fi flaticon-user" />
                {t('landing.profile')}
              </li>
              <li className="hide" id="ddl-change-settings">
                <span className="icon fi flaticon-gear" />
                {t('landing.settings')}
              </li>
              <li id="ddl-change-language">
                <span className="icon fi flaticon-subject" />
                <div>
                  <div>{t('landing.changeLanguage')}</div>
                  <div className="sub-text">{t('landing.setLanguage')}</div>
                </div>
                <div className="submenu">
                  <ul>
                    <li
                      tabIndex="-2"
                      onKeyPress={() => {}}
                      onClick={() => changeLanguagePreference(Languages.EN)}
                      id="ddl-change-language-en"
                    >
                      <span className="linkbutton">English</span>
                    </li>
                    <li
                      tabIndex="-3"
                      onKeyPress={() => {}}
                      onClick={() => changeLanguagePreference(Languages.FR)}
                      id="ddl-change-language-fr"
                    >
                      <span className="linkbutton">French</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="hide" id="ddl-help">
                <span className="icon fi flaticon-question-1" />
                {t('landing.help')}
              </li>
              {isOktaLogin && (
                <li onClick={setSecurityModalVisible}>
                  <LockFilled className="icon security-icon" />
                  <div>
                    <div>{t('landing.accountSecurity')}</div>
                    <div className="sub-text">{t('landing.accountSecurity.mfa')}</div>
                  </div>
                </li>
              )}
              <li onClick={logout} id="ddl-logout">
                <span className="icon fi flaticon-logout" />
                {t('landing.logOut')}
              </li>
            </ul>
          </div>
        )}
        {settingsVisible && (
          <div
            role="button"
            tabIndex="-4"
            onKeyPress={() => {}}
            className="backgroundClickContainer"
            onClick={setSettingsVisible}
          />
        )}
      </div>
      <div role="button" tabIndex="-4" onKeyPress={() => {}} className="sidemenu-toggle" onClick={setSettingsVisible}>
        <i className="fi flaticon-menu"></i>
      </div>
      {isOktaLogin && (
        <AccountSecurityModal
          visible={securityModalVisible}
          setVisibility={setSecurityModalVisible}
          loginType={loginType}
        />
      )}
    </>
  );
}

export default withTranslation()(UserWidget);
