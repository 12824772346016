import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppRouteTypes } from 'enums';
import * as auth from 'util/auth';

class ProtectedRoute extends React.Component {
  getRouteVariables = (type, isAuthenticated) => {
    switch (type) {
      case AppRouteTypes.LOGIN_ROUTE:
        return [!isAuthenticated, 'suite/dashboard'];
      case AppRouteTypes.PRIVATE_ROUTE:
        return this.getVariablesForPrivateRoutes(isAuthenticated);
      case AppRouteTypes.ERROR_ROUTE:
        return this.getVariablesForErrorRoutes(isAuthenticated);
      default:
        return [isAuthenticated, '/login'];
    }
  };

  getVariablesForPrivateRoutes = isAuthenticated => {
    // if (isAuthenticated) {
    //   if (this.isNoRoleError()) {
    //     return [!isAuthenticated, '/error', { isNoRoleError: true }];
    //   }
    // }
    return [isAuthenticated, '/login'];
  };

  getVariablesForErrorRoutes = isAuthenticated => {
    return [isAuthenticated, '/login'];
  };

  render() {
    const { component: Component, type, ...rest } = this.props;
    const isAuthenticated = auth.isLoggedIn();

    const [switcher, redirectPath] = this.getRouteVariables(type, isAuthenticated);
    return (
      <Route
        {...rest}
        render={props => {
          return switcher ? (
            <Component {...rest} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: redirectPath
              }}
            />
          );
        }}
      />
    );
  }
}

ProtectedRoute.propTypes = {
  type: PropTypes.string.isRequired
};

export default withRouter(ProtectedRoute);
