import React from 'react';

import AppLoaderBox from './AppLoaderBox';

function AppLoader() {
  return (
    <div className="app-loader">
      <div className="boxes">
        <AppLoaderBox />
        <AppLoaderBox />
        <AppLoaderBox />
        <AppLoaderBox />
      </div>
    </div>
  );
}

export default AppLoader;
