export const Languages = {
  EN: 'en',
  FR: 'fr'
};

export const AppRouteTypes = {
  LOGIN_ROUTE: 'LoginRoute',
  PRIVATE_ROUTE: 'PrivateRoute',
  ERROR_ROUTE: 'ErrorRoute'
};

export const LoginTypes = {
  OKTA_LOGIN: 'Okta',
  AD_LOGIN: 'ad'
};
