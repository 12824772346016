import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  fr: { translation: fr }
};

const options = {
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: 'lng',
  lookupLocalStorage: 'i18nextLng',
  caches: ['cookie', 'localStorage'],
  excludeCacheFor: ['cimode']
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: options,
    resources,
    ns: ['translation'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    fallbackNS: `translation`,
    // lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false
  });

export default i18n;
