/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { Link } from 'react-router-dom';

import * as Auth from 'util/auth';
import { Languages, LoginTypes } from 'enums/index';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS } from 'util/constants';

const isReleased = process.env.REACT_APP_IS_RELEASED === 'true';

const envLogo = ``; // getEnvLabel();

const handleLogin = loginType => {
  Auth.login(loginType);
};

function LoginPage({ changeLanguage }) {
  const { update: updateIntercom } = useIntercom();
  const { t, i18n } = useTranslation();

  const { language } = i18n;

  useEffect(() => {
    updateIntercom({ languageOverride: language, email: null });
  }, [language]);

  return (
    <div className={`wrapper new-login-wrapper ${envLogo}`}>
      <Row className="section-1">
        <Col span={8} className="login-left-section">
          <Row>
            <Col span={24} className="language-wrap">
              <span className="Language-selection-newcustomer">
                <Button
                  type="link"
                  className={`ant-btn-language
                  ${language === Languages.EN && `lang-selected`}
                  `}
                  onClick={() => changeLanguage(Languages.EN)}
                >
                  {Languages.EN.toUpperCase()}
                </Button>
                <span> I </span>
                <Button
                  type="link"
                  className={`ant-btn-language
                    ${language === Languages.FR && 'lang-selected'}`}
                  onClick={() => changeLanguage(Languages.FR)}
                >
                  {Languages.FR.toUpperCase()}
                </Button>
              </span>
            </Col>
            <Col span={24} className="login-wrap">
              <span className="icon-sysco-portal"></span>
              <h2 className="login-text">{t('landing.logIn.logIntoContinue')}</h2>
            </Col>

            <Col span={24} className="btn-login-wrap">
              <Button
                type="link btn-login"
                disabled={!isReleased}
                onClick={() => {
                  handleLogin(LoginTypes.OKTA_LOGIN);
                }}
              >
                {t('landing.logIn.logIn')}
              </Button>
            </Col>
            <Col span={24}>
              <p>{t('landing.logIn.newToPortal')}</p>
            </Col>
            <Col span={24}>
              <Link to="/signup">
                <Button type="link btn-signup" disabled={!isReleased}>
                  {t('landing.logIn.signUp')}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="row-ad-login">
            <Col span={24}>
              <p>{t('landing.logIn.ad.syscoAssociate')}</p>
            </Col>
            <Col span={24} className="btn-login-wrap">
              <Button
                type="link btn-login"
                disabled={!isReleased}
                onClick={() => {
                  handleLogin(LoginTypes.AD_LOGIN);
                }}
              >
                {t('landing.logIn.ad.logIn')}
              </Button>
            </Col>
          </Row>
          <Row>
            <div className=" delivery-footer">
              <Col span={24} className=" footer-inner">
                <Col span={24}>
                  <a href="https://www.facebook.com/SyscoCanada" target="_blank" className="icon-fb"></a>
                  <a href="https://www.instagram.com/syscocanada/" target="_blank" className="icon-instagram"></a>
                  <a
                    href="https://www.linkedin.com/company/sysco-canada"
                    target="_blank"
                    className="icon-linkedin "
                  ></a>
                  <a
                    href="https://open.spotify.com/show/5NYP9a2pq2FDdhUQBUxCC5"
                    target="_blank"
                    className="icon-spotify"
                  ></a>
                </Col>
                <Col span={24} className="login-bottom-links">
                  <a className="tandc" target="_blank" href={TERMS_AND_CONDITIONS[language]}>
                    {t('landing.logIn.termsAndCondition')}
                  </a>
                  <a className="privacy" target="_blank" href={PRIVACY_POLICY_URL[language]}>
                    {t('landing.logIn.privacyPolicy')}
                  </a>
                  <p className="copyright">©2021 {t('landing.logIn.allRightsReserved')} Sysco Corporation</p>
                  <p className="browser-compatibility">{t('landing.logIn.browserCompatibility')}</p>
                </Col>
              </Col>
            </div>
          </Row>
        </Col>
        <Col span={16} className="login-right-section">
          <div className="login-welcome-text-wrap">
            <h1 className="login-welcome-text">{t('landing.logIn.logInHeader')}</h1>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LoginPage;
/* eslint-enable */
