import React from 'react';
import logo from 'styles/images/logo.svg';
import logowhite from 'styles/images/logo-white.svg';
import appLogo from 'styles/images/app-logos/delivery-app-logo.svg';
// import getEnvLabel from 'helpers/GetEnvLabel';
import { Link } from 'react-router-dom';
import UserWidget from './UserWidget';
import CustomerSwitcher from './CustomerSwitcher';
import AppDrawerDropdown from './AppDrawerDropdown';
import CustomerFilter from './CustomerFilter';

function AppBar({
  userDetails,
  selectedCustomer,
  selectCustomer,
  isCustomerSelectorVisible,
  isCustomerFilterVisible,
  changeLanguage,
  specialOrderCustomers,
  setSelectedSpecialOrderCustomer,
  selectedSpecialOrderCustomer
}) {
  const envLogo = ``;
  const { esyscoUserId: username = '', userPreference } =
    userDetails && userDetails.userDetails ? userDetails.userDetails : {};
  const { initMfaBanner } = userPreference || {};
  const { loginType } = userDetails || {};

  return (
    <div id="app-bar" className="app-bar">
      <div className={`branding ${envLogo}`}>
        <Link to="/suite/dashboard">
          <img src={logo} className="logo" alt="Sysco Portal" />
          <img src={logowhite} className="logo logo-white" id="logo-white" alt="Sysco Portal" />
        </Link>
        <div className="app-logo-wrapper">
          <img src={appLogo} id="app-logo" alt="Sysco Logo" className="app-logo" />
        </div>
      </div>
      {userDetails && isCustomerSelectorVisible && (
        <CustomerSwitcher
          customerList={userDetails ? userDetails.customerList : []}
          selectedCustomer={selectedCustomer}
          selectCustomer={selectCustomer}
        />
      )}
      {userDetails && isCustomerFilterVisible && (
        <CustomerFilter
          customerList={specialOrderCustomers}
          selectedSpecialOrderCustomer={selectedSpecialOrderCustomer}
          setSelectedSpecialOrderCustomer={setSelectedSpecialOrderCustomer}
        />
      )}
      <div className="spacer" />
      <div className="account-widget">
        <div className="messages hide">
          <div className="label">Message</div>
          <span className="icon fi flaticon-mail" />
        </div>
        <div className="notifications hide">
          <span className="icon fi flaticon-bell" />
          <div className="count">99</div>
        </div>
        <AppDrawerDropdown />
        <UserWidget
          selectCustomer={selectCustomer}
          loginType={loginType}
          username={username}
          initMfaBanner={initMfaBanner}
          changeLanguage={changeLanguage}
        />
      </div>
    </div>
  );
}

export default AppBar;
