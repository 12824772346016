import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CustomerFilter({
  customerList,
  selectedSpecialOrderCustomer,
  setSelectedSpecialOrderCustomer
}) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);

  useEffect(() => {
    if (selectedSpecialOrderCustomer?.name || selectedSpecialOrderCustomer?.id) {
      const customerName =
        selectedSpecialOrderCustomer?.id === '0'
          ? t('specialOrders.allCustomers')
          : `${selectedSpecialOrderCustomer?.name}-${selectedSpecialOrderCustomer?.id}`;
      setTitle(customerName);
    }
  }, [setTitle, selectedSpecialOrderCustomer, t]);

  useEffect(() => {
    const filteredList = customerList.filter(customer =>
      `${customer?.name}-${customer?.id}`.toLowerCase().includes(filterText.toLowerCase())
    );

    const allCustomersFlag = filteredList.find(customer => customer?.id === '0');
    const otherCustomers = filteredList.filter(customer => customer?.id !== '0');

    // Sort the remaining customers alphabetically
    const sortedOtherCustomers = otherCustomers.sort((a, b) => {
      const nameA = a?.name || `${a?.opco}-${a?.id}`;
      const nameB = b?.name || `${b?.opco}-${b?.id}`;
      return nameA.localeCompare(nameB);
    });

    const sortedList = allCustomersFlag ? [allCustomersFlag, ...sortedOtherCustomers] : sortedOtherCustomers;

    setFilteredCustomerList(sortedList);
  }, [customerList, filterText]);

  const openMenu = useCallback(() => {
    setVisible(true);
  }, []);

  const closeMenu = useCallback(() => {
    setVisible(false);
  }, []);

  const handleInputChange = useCallback(
    event => {
      setFilterText(event.target.value);
      openMenu();
    },
    [openMenu]
  );

  const handleCustomerSelect = useCallback(
    customer => {
      setSelectedSpecialOrderCustomer(customer);
      setFilterText('');
      const customerName = customer?.id === '0' ? t('specialOrders.allCustomers') : `${customer?.name}-${customer?.id}`;
      setTitle(customerName);
      closeMenu();
    },
    [setSelectedSpecialOrderCustomer, closeMenu]
  );

  const handleOnClick = useCallback(() => {
    openMenu();
    setTitle('');
  }, []);

  const handleBackgroundClick = useCallback(() => {
    closeMenu();
    if (selectedSpecialOrderCustomer?.name || selectedSpecialOrderCustomer?.id) {
      const customerName =
        selectedSpecialOrderCustomer?.id === '0'
          ? t('specialOrders.allCustomers')
          : `${selectedSpecialOrderCustomer?.name}-${selectedSpecialOrderCustomer?.id}`;
      setTitle(customerName);
    }
  }, [selectedSpecialOrderCustomer]);

  return (
    <React.Fragment>
      <div role="button" tabIndex="0" className="organisation">
        <div className="current-vendor" tabIndex="0" role="button" onClick={handleOnClick}>
          <div className="type customer-icon" />
          <input
            type="text"
            value={filterText}
            onChange={handleInputChange}
            placeholder={title}
            className="search-input"
            onClick={handleOnClick}
            title={title}
          />
          <i className="fi flaticon-caret-down" tabIndex="0" role="button" onClick={handleOnClick}></i>
        </div>
        <ul id="vendorlist" className={visible ? 'vendor-switcher show' : 'vendor-switcher'}>
          {filteredCustomerList.map(customer => (
            <li key={customer?.id} onClick={() => handleCustomerSelect(customer)}>
              {customer?.id === '0' ? t('specialOrders.allCustomers') : `${customer?.name}-${customer?.id}`}
            </li>
          ))}
        </ul>
      </div>
      {visible && (
        <div
          role="button"
          tabIndex="-4"
          onKeyPress={() => {}}
          className="backgroundClickContainer"
          onClick={handleBackgroundClick}
        />
      )}
    </React.Fragment>
  );
}
