// TODO: change all these hardcoded values

export const ALB_URL = 'https://bff-dev.sysco-one.canadapricing-np.us-east-1.aws.sysco.net';
export const FE_BASE_URL = 'https://d3ihh43kgff9gl.cloudfront.net';
export const EMPTY_STRING = '';
export const MAX_LENGTH_NAME = 100;
export const MAX_LENGTH_EMAIL = 200;
export const MAX_LENGTH_CUSTOMER_NUMBER = 10;
export const PHONE_NUMBER_LENGTH = 11;
export const PHONE_NUMBER_COUNTRY_CODE = 'ca';
export const NAME_VALIDATION_PATTERN = "^(?! +$)[a-zA-Z.'- ]+(?:s+[a-zA-Z.'- ]+)*$";
export const EMAIL_VALIDATION_PATTERN = '^[^ ]+@[^ ]+.[a-z]{2,6}$';
export const NUMBER_VALIDATION_PATTERN = '/[^0-9]+/g';
export const PHONE_NUMBER_MASK = '(...)-...-....';

export const PRIVACY_POLICY_URL = {
  en: 'https://sysco.ca/Privacy-Policy.html',
  fr: 'https://www.sysco.ca/fr/Privacy-Policy.html'
};

export const TERMS_AND_CONDITIONS = {
  en: 'https://www.sysco.ca/Terms.html',
  fr: 'https://www.sysco.ca/fr/Terms.html'
};

export const LANGUAGE_PREFERENCE = { en: 'English', fr: 'French' };

export const SESSION_DATA_PREFIX = 'SYSCO_PORTAL_SESSION';
