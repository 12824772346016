import React from 'react';
import { Button, Col } from 'antd';
import '../styles/apps/signup/_signup.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ThankYouPage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h1 className="title">{t('landing.thankYou')}</h1>
      <h2>{t('landing.thankYou.msg')}</h2>
      <Col span={12} className="submit-col ok-col">
        <Link to="/login">
          <Button className="submit-btn">{t('landing.thankYou.ok')}</Button>
        </Link>
      </Col>
    </React.Fragment>
  );
}
