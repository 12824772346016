import axios from 'axios';
import Cookies from 'universal-cookie';

import * as Auth from 'util/auth';
import openNotification from 'components/openNotification';
import i18n from '_i18n';

const baseURL = process.env.REACT_APP_BFF;

const api = axios.create({
  baseURL,
  timeout: process.env.REACT_APP_HTTP_TIMEOUT || 60000,
  withCredentials: true
});

function getHeaders() {
  const headers = { 'Content-Type': 'application/json' };
  if (process.env.REACT_APP_ENV === 'local') {
    const cookies = new Cookies();
    const userClaims = cookies.get('user-claims');
    if (userClaims) headers['x-amzn-oidc-data'] = userClaims;
  }

  return headers;
}

function onAxiosError(error) {
  if (error?.response?.status === 401) {
    openNotification({
      description: i18n.t('landing.errorNotification.description.sessionExpired'),
      className: 'error',
      message: i18n.t('landing.errorNotification.message.sessionExpired')
    });
    Auth.logout();
    return { data: {} };
  }

  throw error;
}

async function postRequest(url, data) {
  try {
    return await api.post(url, data, {
      headers: getHeaders()
    });
  } catch (error) {
    return onAxiosError(error);
  }
}

async function getRequest(url, params = {}) {
  try {
    return await api.get(url, {
      headers: getHeaders(),
      params
    });
  } catch (error) {
    return onAxiosError(error);
  }
}

async function putRequest(url, data) {
  try {
    return await api.put(url, data, {
      headers: getHeaders()
    });
  } catch (error) {
    return onAxiosError(error);
  }
}

async function deleteRequest(url) {
  try {
    return await api.delete(url, {
      headers: getHeaders()
    });
  } catch (error) {
    return onAxiosError(error);
  }
}

async function patchRequest(url, data) {
  try {
    return await api.patch(url, data, {
      headers: getHeaders()
    });
  } catch (error) {
    return onAxiosError(error);
  }
}

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders, patchRequest };
