// import * as http from '_http';
import { LoginTypes } from 'enums/index';
import { SESSION_DATA_PREFIX } from './constants';

function clearSessionAppData() {
  Object.entries(localStorage)
    .map(x => x[0])
    .filter(x => x.startsWith(SESSION_DATA_PREFIX))
    .map(x => localStorage.removeItem(x));
}

const logoutCallbacks = [clearSessionAppData];

export const setLogoutCallback = callback => {
  if (callback) logoutCallbacks.push(callback);
  else console.log('Logout callback is null');
};

/**
 * Call login endpoint and set the auth flag to true in local storage
 */
export function login(loginType) {
  // https://stackoverflow.com/questions/14197276/how-can-i-force-window-location-to-make-an-http-request-instead-of-using-the-cac/14197329
  const timestampQueryParam = `timestamp=${new Date().getTime()}`;
  let param = `dashboard/${loginType === LoginTypes.AD_LOGIN ? 'ad' : ''}login?host=localhost&${timestampQueryParam}`;
  if (process.env.REACT_APP_ENV !== 'local') {
    param = `dashboard/${loginType === LoginTypes.AD_LOGIN ? 'ad' : ''}login?${timestampQueryParam}`;
  }

  localStorage.setItem(`s1_auth${process.env.REACT_APP_ENV}`, 'true');
  window.location.assign(`${process.env.REACT_APP_ALB_URL}/${param}`);
}

/**
 * When click the logout button clear the local storage and redirect to cognito logout
 */
export function logout() {
  const timestampQueryParam = `timestamp=${new Date().getTime()}`;
  let param = `dashboard/logout?host=localhost&${timestampQueryParam}`;
  if (process.env.REACT_APP_ENV !== 'local') {
    param = `dashboard/logout?${timestampQueryParam}`;
  }
  localStorage.removeItem(`s1_auth${process.env.REACT_APP_ENV}`);

  // call logout callbacks
  try {
    logoutCallbacks.forEach(callback => callback());
  } catch (error) {
    console.log('LOGOUT_CALLBACK_ERROR: ', error);
  }

  window.location.assign(`${process.env.REACT_APP_ALB_URL}/${param}`);

  // log-out functionality - in progress
  // http.getRequest(`${param}`);
}

/**
 * When logout clear the local storage and redirect to login page
 */
export function logOutRedirection(errorCode) {
  let param = 'dashboard/login';
  if (errorCode !== undefined) {
    param = `dashboard/login?error=${errorCode}`;
  }
  localStorage.removeItem(`s1_auth${process.env.REACT_APP_ENV}`);
  window.location.assign(`${process.env.REACT_APP_FE_BASE_URL}/${param}`);
}

/**
 * Return true/false if the user is logged
 */
export function isLoggedIn() {
  return localStorage.getItem(`s1_auth${process.env.REACT_APP_ENV}`) !== null;
}

export function mfaSetup(loginType) {
  if (loginType === LoginTypes.OKTA_LOGIN) {
    const currentPath = window.location.href;
    window.location.assign(`${process.env.REACT_APP_OKTA_MFA_SETUP_URL}&returnTo=${currentPath}`);
  }
}
