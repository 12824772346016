import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as http from '_http';
import { Modal, Button, Spin, Icon } from 'antd';
import { mfaSetup } from 'util/auth';
import openNotification from 'components/openNotification';

function AccountSecurityModal({ t, visible, setVisibility, loginType }) {
  const [idpUserDetails, setIdpUserDetails] = useState(null);
  const [idpUserLoading, setIdpUserLoading] = useState(false);

  const handleOk = () => {
    setVisibility();
    mfaSetup(loginType);
  };

  useEffect(() => {
    const getIdpUserDetails = async () => {
      try {
        setIdpUserLoading(true);
        const { data: userDetails } = await http.getRequest(`/user/idp`);
        setIdpUserDetails(userDetails);
      } catch (e) {
        openNotification({
          description: t('landing.errorNotification.description.accountSecurityError'),
          className: 'error',
          message: t('landing.errorNotification.message.accountSecurityError')
        });
      }
      setIdpUserLoading(false);
    };

    if (visible && !idpUserDetails) {
      getIdpUserDetails();
    }
  }, [visible]);

  return (
    <Modal
      wrapClassName="account-security-modal"
      visible={visible}
      title={<span className="modal-title">{t('landing.accountSecurity.modalTitle')}</span>}
      footer={null}
      onOk={handleOk}
      onCancel={setVisibility}
      bodyStyle={{ paddingTop: 0 }}
    >
      <div className="content">
        <SecurityContentView
          t={t}
          idpUserLoading={idpUserLoading}
          idpUserDetails={idpUserDetails}
          handleOk={handleOk}
          setVisibility={setVisibility}
        />
      </div>
    </Modal>
  );
}

const SecurityContentView = ({ t, idpUserLoading, idpUserDetails, handleOk, setVisibility }) => {
  if (idpUserLoading) return <Spin className="spinner" indicator={<Icon type="loading" spin />} />;
  if (idpUserDetails && idpUserDetails.mfaEnabled) {
    return (
      <>
        <div className="title">{t('landing.accountSecurity.protected.title')}</div>
        <div className="description">{t('landing.accountSecurity.protected.description')}</div>
        <div className="sub-description">{t('landing.accountSecurity.protected.subDescription')}</div>
        <Button className="continue-button" key="submit" shape="round" type="danger" onClick={handleOk}>
          {t('landing.accountSecurity.protected.continue')}
        </Button>
        <Button className="return-button" shape="round" key="back" onClick={setVisibility}>
          {t('landing.accountSecurity.protected.return')}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="title">{t('landing.accountSecurity.unprotected.title')}</div>
      <div className="sub-title">{t('landing.accountSecurity.unprotected.subTitle')}</div>
      <div className="description">{t('landing.accountSecurity.unprotected.description')}</div>
      <div className="sub-description">{t('landing.accountSecurity.unprotected.subDescription')}</div>
      <Button className="continue-button" key="submit" shape="round" type="primary" onClick={handleOk}>
        {t('landing.accountSecurity.unprotected.continue')}
      </Button>
      <Button className="return-button" shape="round" key="back" onClick={setVisibility}>
        {t('landing.accountSecurity.unprotected.return')}
      </Button>
    </>
  );
};

export default withTranslation()(AccountSecurityModal);
