import React, { useCallback, useEffect, useState } from 'react';

export default function CustomerSwitch({ customerList, selectedCustomer, selectCustomer }) {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (selectedCustomer?.customerDetails?.customerInfo?.name || selectCustomer?.opco || selectCustomer?.customerId) {
      const customerName = `${selectedCustomer?.customerDetails?.customerInfo?.name ||
        `${selectCustomer?.opco}-${selectCustomer?.customerId}`}`;
      setTitle(customerName);
    }
  }, [setTitle, selectedCustomer]);

  const toggleMenu = useCallback(() => {
    setVisible(prev => !prev);
  }, []);

  const sortedCustomerList = customerList.sort((c1, c2) => {
    if (c1?.customerDetails?.customerInfo?.name === c2?.customerDetails?.customerInfo?.name) return 0;

    // nulls sort after anything else
    if (c1?.customerDetails?.customerInfo?.name === null) return -1;
    if (c2?.customerDetails?.customerInfo?.name === null) return 1;

    // sort in ascending order
    return c1?.customerDetails?.customerInfo?.name < c2?.customerDetails?.customerInfo?.name ? -1 : 1;
  });

  return (
    <React.Fragment>
      <div role="button" tabIndex="0" className="organisation" onClick={toggleMenu} onKeyPress={toggleMenu}>
        <div className="current-vendor">
          <div className="type location-icon" />
          <div id="vendorname" className="location-dropdown-name-address" title={`${title}`}>
            {title}
          </div>
          <i className="fi flaticon-caret-down"></i>
        </div>
        <ul id="vendorlist" className={visible ? 'vendor-switcher show' : 'vendor-switcher'}>
          {sortedCustomerList.map(customer => (
            <li key={customer?.customerId} onClick={() => selectCustomer(customer)}>
              {`${customer?.customerDetails?.customerInfo?.name || `${customer?.opco}-${customer?.customerId}`}`}
            </li>
          ))}
        </ul>
      </div>
      {visible && (
        <div
          role="button"
          tabIndex="-4"
          onKeyPress={() => {}}
          className="backgroundClickContainer"
          onClick={toggleMenu}
        />
      )}
    </React.Fragment>
  );
}
