import React from 'react';

function AppLoaderBox() {
  return (
    <div className="box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default AppLoaderBox;
