import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppThumbnail from './AppThumbnail';
import AppCollection from './appCollection';

class AppDrawerDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.toggleAppMenu = this.toggleAppMenu.bind(this);
  }

  toggleAppMenu() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  render() {
    const { visible } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="app-menu-drawer hide">
          <span
            role="button"
            tabIndex="0"
            className="icon fi flaticon-matrix"
            onClick={this.toggleAppMenu}
            onKeyPress={this.toggleAppMenu}
          />

          <div className={visible ? 'app-menu show' : 'app-menu'}>
            <div className="menu-header">
              <div className="title">
                <span className="icon fi flaticon-app"></span>
                {t('landing.appThumbnailMenu')}
              </div>
              <div className="spacer"></div>
              <div className="homenav">
                <Link to="/suite/dashboard">
                  <span className="icon fi flaticon-home"></span>
                </Link>
              </div>
            </div>
            <div className="app-grid menumode">
              <ul className="apps">
                {AppCollection.map(({ divClassName, spanClassName, appName }) => (
                  <AppThumbnail
                    key={appName}
                    divClassName={divClassName}
                    spanClassName={spanClassName}
                    appName={t(appName)}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
        {visible && (
          <div
            role="button"
            tabIndex="-4"
            onKeyPress={() => {}}
            className="backgroundClickContainer"
            onClick={this.toggleAppMenu}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(AppDrawerDropdown);
